/* 
  This is the build for the TOS page its mainly static content
  and it uses the global components for the navbar and footer
*/

import React from "react";
import TopMenu from "../../global/components/nav/navbar";
import Footer from "../../global/components/footer/footer";
import "./terms.css";

export default function TermsOfService() {
  return (
    <>
    <TopMenu page="other" />
      <div className="TOS-container">
        
        <div className="TOS-content">
          
          <div className="TOS-header">
            <h1 className="TOS-title">EasySpeak Terms of Service</h1>
          </div>

          <div className="TOS-content-block">
            <p className="TOS-section-title-initial"> EasySpeak app Terms and Conditions of Use </p>
            <p className="TOS-section-text-initial">
              The following Terms and Conditions of Use (referred to as the "Agreement") constitute a legally binding agreement between EasySpeak, Inc. (referred to as the "Company"), a Washington corporation, and yourself (hereinafter referred to as "you" or "your"). This Agreement governs your utilization of the services provided through the EasySpeak app (referred to as the "Services"). PRIOR TO UTILIZING THE SERVICES, YOU ARE REQUIRED TO ACCEPT ALL THE TERMS STIPULATED IN THIS AGREEMENT. By creating an account or engaging with the Services in any other manner, you acknowledge that you have read, comprehended, and consent to abide by all the terms delineated in this Agreement. The Company retains the right to revise and update this Agreement at any time, and in such instances, it will provide notification to you and all other users regarding the modifications to the Agreement. Your continued use of the Services indicates your acceptance of these changes, and you thereby agree to the Agreement as amended. It is important to note that you are not authorized to modify or alter this Agreement unless such modification or alteration is expressly agreed upon in writing by the Company.
            </p>
          </div>
        
          <div className="TOS-content-block">
            <p className="TOS-section-title">Your Use of the Services</p>
            <p className="TOS-section-text">
              You are permitted to utilize the Services in accordance with the terms and conditions outlined in this Agreement. Your usage of the Services is your sole responsibility. It is explicitly agreed that you shall employ the Services solely for lawful and legitimate purposes. Additionally, your use of the Services must align with the terms stipulated in this Agreement, as periodically modified by the Company, and any other terms, rules, or guidelines furnished by the Company pertaining to your utilization of the Services. Furthermore, you are prohibited from employing the Services in a manner that either (i) contravenes the terms of this Agreement or (ii) has the potential to detrimentally impact the public image, reputation, or goodwill of the Company. The Company reserves the right to conduct maintenance and upgrades on its systems, leading to temporary unavailability of the Services. While efforts will be made to schedule downtime between 2:00 a.m. and 4:00 a.m., Pacific Standard Time, or during periods of low usage such as weekends, holidays, and off-peak business hours, it is acknowledged that, under certain circumstances, the Company may need to perform work on its systems at alternative times.
            </p>
          </div>
          
          <div className="TOS-content-block">
            <p className="TOS-section-title"> Suspension or Termination of the Services and the Agreement  </p>
            <p className="TOS-section-text">
            Notwithstanding any contrary provision contained herein and without limiting the Company's other legal or equitable remedies, the Company reserves the right to take immediate actions, including issuing a warning, temporarily suspending, indefinitely suspending, or terminating your access to the Services and/or terminating this Agreement (solely between the Company and you), without prior notice or incurring any liability, for any reason whatsoever, as determined solely by the Company. Such reasons may include, but are not limited to, (a) your violation of this Agreement; (b) the Company's inability to verify or authenticate any information provided by you (a process undertaken at the Company's sole and absolute discretion); or (c) the Company's belief, at its sole and absolute discretion, that your actions may pose a risk of legal liability for you, the Company, or any third party, or may result in such legal liability. In the event of termination of this Agreement solely between the Company and you, any provision in this Agreement imposing ongoing obligations on your part will persist despite the termination of the Agreement.

            </p>
          </div>

          <div className="TOS-content-block">
            <p className="TOS-section-title"> User Content; Password Protection</p>
            <p className="TOS-section-text">
            You affirm that all information provided by you in connection with your utilization of the Services is truthful, complete, and up-to-date. Furthermore, you commit to promptly updating such information in the event of any changes. In the case where you are furnished with a user ID and/or password for accessing the Services, it is your responsibility to maintain the confidentiality of your user ID and password, treating them as privileged information. You acknowledge that you bear the responsibility for any communications, transactions, or use of the Services conducted with your user ID and password. This includes any associated fees, charges, liabilities, or other obligations arising from such utilization. It is your duty to modify your password if you suspect that it has been compromised or might be subject to misuse. The assignment of your password or any rights under this Agreement requires the prior written consent of the Company, and any attempt to assign such without consent will be deemed void, constituting a breach of this Agreement.

            </p>
          </div>  
          
          <div className="TOS-content-block">
            <p className="TOS-section-title"> User Materials  </p>
            <p className="TOS-section-text">
            To access the Services, you have the option to generate or furnish information, messages, data, and other materials in electronic format, collectively referred to as "User Materials." You assert that you possess all requisite third-party rights, including but not limited to copyrights, for any User Materials originating from third parties. It is your responsibility to ascertain the need and, if necessary, acquire any licenses required for utilizing third-party information or content integrated into the User Materials. You undertake not to utilize the Services for, and ensure that the User Material does not encompass, any infringing, illegal, sexually explicit, hateful, vulgar, threatening, abusive, harassing, defamatory, or racially, ethnically, or otherwise objectionable content. This includes materials that could potentially result in liability for the Company or have an adverse impact on the Company's public image, reputation, or goodwill.
            </p>
            <p className="TOS-section-text-important">
            YOU ARE SOLELY RESPONSIBLE FOR CREATING BACK-UP AND ARCHIVAL COPIES OF ALL USER MATERIALS. UNDER NO CIRCUMSTANCES WILL THE COMPANY BE ACCOUNTABLE TO YOU, OR ANY OTHER INDIVIDUAL OR ENTITY, FOR ANY LOSS, CORRUPTION, OR ALTERATION OF USER MATERIALS, OR FOR ANY LOSS ARISING FROM SECURITY BREACHES, INCLUDING, BUT NOT LIMITED TO, ANY SPECIAL, DIRECT, INDIRECT, OR OTHER DAMAGES OF ANY NATURE.
            </p>
          </div>  
          
          <div className="TOS-content-block">
            <p className="TOS-section-title">  Use of Data</p>
            <p className="TOS-section-text">
            Any data, contacts, email lists, email addresses, and User Materials supplied by you, collectively referred to as "Your Data," will be gathered, safeguarded, utilized, and disclosed in conformity with the Company's privacy policy, known as the "EasySpeak Privacy Policy." This privacy policy may undergo periodic updates and is consistently accessible for review at https://easyspeak.io/privacy-policy. By consenting to the terms articulated in this Agreement, you confirm receipt of, and consent to, the provisions outlined in the EasySpeak Privacy Policy.

            </p>
          </div>  
          
          <div className="TOS-content-block">
            <p className="TOS-section-title"> Disclaimer of Warranty  </p>
            <p className="TOS-section-text">
            THE SERVICES ARE PROVIDED "AS IS," "AS AVAILABLE," AND WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. THIS INCLUDES, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE, AND THE COMPANY EXPRESSLY DISCLAIMS SUCH WARRANTIES. SPECIFICALLY, THE COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE DELIVERED IN AN UNINTERRUPTED, SECURE, OR ERROR-FREE MANNER. ADDITIONALLY, THERE IS NO WARRANTY REGARDING THE RESULTS OBTAINED FROM THE SERVICES OR THE ACCURACY OR RELIABILITY OF ANY CONTENT CONTAINED IN OR PROVIDED THROUGH THE SERVICES.
            </p>
            <p className="TOS-section-text-important">
            COMPANY MAKES NO GUARANTEES, AND NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED BY COMPANY, ITS AGENTS, OR EMPLOYEES SHALL CREATE ANY WARRANTY. THE USE OF THE SERVICES IS ENTIRELY AT YOUR OWN RISK.
            </p>

          </div>  
          
          <div className="TOS-content-block">
            <p className="TOS-section-title"> Limitation of Liability  </p>
            <p className="TOS-section-text">
              COMPANY SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, EXEMPLARY, CONSEQUENTIAL, OR INCIDENTAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE SERVICES. THIS INCLUDES, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, DATA, OR BUSINESS INTERRUPTION. SUCH DAMAGES MAY ARISE FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, DATA OR E-MAILS, ERRORS, DEFECTS, VIRUSES, OR OTHER MALICIOUS CODE, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE. EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, COMPANY WILL NOT BE HELD LIABLE.
            </p>
            <p className="TOS-section-text-important">
              IN NO EVENT SHALL THE LIABILITY OF COMPANY TO YOU FOR ANY CLAIM WHATSOEVER, WHETHER ARISING IN CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE OR STRICT LIABILITY), OR FOR ANY CLAIM MADE AGAINST COMPANY BY ANY OTHER PARTY, EXCEED FIVE DOLLARS ($5.00).
            </p>

          </div>  
          
          <div className="TOS-content-block">
            <p className="TOS-section-title"> Indemnification   </p>
            <p className="TOS-section-text">
              You consent to defend, indemnify, and hold harmless the Company, along with its officers, directors, employees, contractors, customers, suppliers, and licensors, from any and all costs, fees, losses, claims, or liabilities. This includes, but is not limited to, all attorneys' fees and expenses, that may be incurred in connection with (a) your violation of this Agreement or any other rules or guidelines furnished to you by the Company, or (b) your utilization of the Services.
            </p>
          </div> 
          
          <div className="TOS-content-block">
            <p className="TOS-section-title"> Legal Compliance; Authority; Binding Nature  </p>
            <p className="TOS-section-text">
              You shall comply with all applicable laws, statutes, ordinances and regulations regarding your use of the Services. You agree, represent and warrant that (i) you understand the terms and conditions of this Agreement and that it constitutes a valid, binding obligation, and (ii) you have full power, authority and legal capacity to enter into this Agreement.
            </p>
          </div>  
          
          <div className="TOS-content-block">
            <p className="TOS-section-title"> Choice of Law; Miscellaneous  </p>
            <p className="TOS-section-text">
              This Agreement shall be governed by and construed in accordance with the laws of the State of Washington, without regard to its choice of law provisions. Any dispute or claim arising out of or relating to this Agreement, or the negotiation or breach thereof, shall be resolved through arbitration in accordance with the Revised Code of Washington, Chapter 7.04A (the "Uniform Arbitration Act") and the current Rules of Commercial Arbitration of the American Arbitration Association. The arbitration proceedings shall take place in Seattle, Washington, and shall be conducted in the English language. The arbitration shall be presided over by a single arbitrator mutually agreeable to the parties, or if no agreement can be reached, then selected by the American Arbitration Association.
              The arbitrator is empowered to award reimbursement of attorneys' fees and other arbitration costs to the prevailing party in a manner deemed appropriate. Any decision rendered by an arbitrator may be affirmed and enforced in any court with jurisdiction. Furthermore, the party that does not prevail in the arbitration shall reimburse the prevailing party for all costs and expenses incurred, including attorneys', arbitrators', and courts' fees and expenses, associated with any action or proceeding under this Agreement.
            </p>
            
            <p className="TOS-section-text-additional">
              DISCLAIMER: EasySpeak is not a licensed educational instructor, language tutor, or teacher. The content provided by EasySpeak is not intended to substitute for guidance from a professional in the field of education. EasySpeak disclaims any liability for errors or omissions in its content and shall not be held responsible for any damages incurred due to the failure to seek competent advice from a professional language tutor, teacher, or educational instructor familiar with your specific needs. Users are strongly encouraged to consult with qualified professionals in the educational field to address their individual learning requirements and circumstances.
            </p>

          </div>  
        

        </div>
      
      </div>
      
      <Footer />
    </>
  );
}