/*
  this component is the third section which explains the benefits of the product 
  this is mported in the home page.
  It is mainly static and only contains text.
*/

import React from "react";
import "./section_3.css";

export default function SectionThree() {
  return (
    <div id='how-it-works' className="section-3-container">
      <div className="section-3-content-container">
          <div className="section-3-content-header">
            <p>How it benefits you</p>
          </div>
          <div className="section-3-content-body">
            <div className="section-3-content-block-container section-3-content-block-1">
              
              <div className="section-3-content-block-header">
                <p>Natural sounding conversation</p>
              </div>
              <div className="section-3-content-block-body">
                <p>Improve the way you speak by honing in on your intonation and accent. EasySpeak is made for you and personalized to fit your needs. You will notice an improvement in pronunciation the more you use our mobile app.</p>
              </div>
            </div>

            <div className="section-3-content-block-container section-3-content-block-2">

              <div className="section-3-content-block-header">
                <p>Unlock new opportunities</p>
              </div>
              <div className="section-3-content-block-body">
                <p>Improving the way you speak languages can create new opportunities in your professional career, educational journey, personal life, and so much more. EasySpeak strives to do our best to assist you in reaching your goals.</p>
              </div>

            </div>
            <div className="section-3-content-block-container section-3-content-block-3">

              <div className="section-3-content-block-header">
                <p>Learn languages faster</p>
              </div>
              <div className="section-3-content-block-body">
                <p>Learning languages can be difficult and improving your pronunciation can be even harder. Be proactive and utilize EasySpeak to accelerate your language learning journey.</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
