/*
  This is the component that handles the style of the excercises section of the home page.
  It is imported in the section_4.jsx file and then rendered in the home page.

  This page is mainly static and only contains text and an image.
*/

import React from "react";
import "./excercises.css";

import excercisesPhone from "../../../../images/excercisesphone.svg"
export default function excercises() {
  return (
    <div className="excercises-container">
      <div className="excercises-content-container">
        <div className="excercises-body-container">
          <div className="excercises-body-header">
            <p className="excercises-body-header" >Personalized pronunciation exercises</p>
          </div>
          <div className="excercises-body-text">
            <p className="excercises-body-text">EasySpeak offers personalized pronunciation exercises that cater to your specific needs. </p>
            <p className="excercises-body-text">We customize a series of exercises designed to target the areas where improvement is most beneficial for you. As you progress, the exercises evolve with you, ensuring a dynamic and effective learning journey.</p>
          </div>
        </div>
        <div className="excercises-phone-container">
          <img className="excercises-phone-svg"src={excercisesPhone} alt="Phone Camera Sample" />
        </div>
      </div>
    </div>
  );
}