/* 
  This is the build for the Privacy Policys page its mainly static content
  and it uses the global components for the navbar and footer
*/

import React from "react";
import TopMenu from "../../global/components/nav/navbar";
import Footer from "../../global/components/footer/footer";
import "./policy.css"

export default function PrivatePolicy() {

  return (
    <div>
      <TopMenu page={'other'} />
      <div className="privacy-policy-container">
        
        <div className="privacy-policy-content-container">

          <div className="privacy-policy-header-contianer">
            <h2 className="privacy-policy-title">EasySpeak Privacy Policy</h2>
            <p className="privacy-policy-date">Last Updated: January 17, 2024</p>
            <p className="privacy-policy-intro">
              This Privacy Policy details how EasySpeak, Inc. collects, uses, discloses, and protects personal information that we collect from you through the EasySpeak mobile application.        
            </p>
            </div>


          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">1. Information we collect</h3>
            <ul className="info-list">
              <li>
                <strong>Personal information:</strong> Information identifying you, like name, email, and contact details.
              </li>
              <li>
                <strong>Usage information:</strong> Data on how you use our services, pages visited, and features utilized.
              </li>
              <li>
                <strong>Device information:</strong> Details about the device you use to access EasySpeak.
              </li>
              <li>
                <strong>Cookies and tracking technologies:</strong> Collection of browsing behavior and preferences.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">2. How we use your information</h3>
            <ul className="info-list">
              <li>
                <strong>To provide our services:</strong> We use your information to provide, maintain, and improve our services.
              </li>
              <li>
                  <strong>To communicate with you:</strong> We use your information to respond to your inquiries, send you service updates, and provide other important information.
              </li>
              <li> 
                  <strong>For marketing and advertising:</strong> We may use your information to send you promotional messages and content. You can opt-out of receiving marketing communications at any time.
              </li>
              <li>
                  <strong>For analytics and improvement:</strong> We analyze the information we collect to understand user behavior and preferences, and to enhance our services.
              </li>
              <li>
                  <strong>For legal and compliance purposes:</strong> We may use your information to comply with applicable laws and regulations, and to protect the rights and safety of EasySpeak, our users, and others.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">3. How we share your information</h3>
            <ul className="info-list">
              <li>
                <strong>With service providers:</strong> We may share your information with third-party service providers who assist us in providing our services.
              </li>
              <li>
                  <strong>With partners:</strong> We use your information to respond to your inquiries, send you service updates, and provide other important information.
              </li>
              <li> 
                  <strong>For legal reasons:</strong> We may disclose your information if required by law or in response to a legal request, such as a subpoena.
              </li>
              <li>
                  <strong>In case of a sale or merger:</strong> If EasySpeak is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title" >4. Your choices and rights</h3>
            <ul className="info-list">
              <li>
                <strong>Access and correction:</strong> You have the right to access and correct your personal information at any time.
              </li>
              <li>
                  <strong>Deletion:</strong> You can request the deletion of your personal information, subject to certain limitations.
              </li>
              <li> 
                  <strong>Opt-out:</strong> You can opt-out of receiving marketing communications and participating in certain tracking activities.
              </li>
              <li>
                  <strong>Data portability:</strong> You have the right to receive a copy of your personal information in a structured, machine-readable format.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title" >5. Security</h3>
            <div className="info-list">
              <blockquote>At EasySpeak, the security of your personal information is a top priority. We implement a variety of technical, administrative, and physical measures designed to protect your information from unauthorized access, disclosure, alteration, and destruction. Here is how we work to keep your data safe:</blockquote>
            </div>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">5.1 Technical safeguards</h3>
            <ul className="info-list">
              <li>
                <strong>Encryption:</strong> We use industry-standard encryption protocols to secure the transmission of data on our platform, including SSL/TLS for data transmitted over the internet.
              </li>
              <li>
                  <strong>Secure data storage:</strong> All personal information is stored in secure databases with restricted access.
              </li>
              <li> 
                  <strong>Access control:</strong> We implement strict access controls to ensure that only authorized personnel have access to your personal information, and their access is limited to what is necessary to perform their duties.
              </li>
              <li>
                  <strong>Regular audits and monitoring:</strong> Our systems are regularly audited and monitored to detect and respond to potential security incidents in a timely manner.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">5.2 Administrative safeguards</h3>
            <ul className="info-list">
              <li>
                <strong>Employee training:</strong> All of our employees undergo regular training on privacy and data security to ensure they understand the importance of protecting personal information.
              </li>
              <li>
                  <strong>Privacy by design:</strong> We incorporate privacy and security considerations into the development and implementation of our products and services.
              </li>
              <li> 
                  <strong>Data minimization:</strong> We collect only the information that is necessary to provide our services and fulfill our obligations to you.
              </li>
              <li>
                  <strong>Incident response plan:</strong> We have a comprehensive incident response plan in place to address any potential security breaches and mitigate their impact.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">5.3 Data retention</h3>
            <ul className="info-list">
              <li>
                <strong>Limited retention:</strong> We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required by law.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">5.4 User protection</h3>
            <ul className="info-list">
              <li>
                <strong>Account security:</strong> Users are responsible for maintaining the security of their account credentials. We encourage you to use strong passwords and to change them regularly.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">5.5 Third-party security</h3>
            <ul className="info-list">
              <li>
                <strong>Vetting of service providers:</strong> We carefully vet any third-party service providers to ensure they meet our security standards and are committed to protecting your information.
              </li>
            </ul>
            </div>

          <div className="privacy-policy-agreement-container">
            <blockquote>
              By implementing these measures, we strive to protect your personal information against unauthorized access and disclosure. However, no system can be completely secure, and we cannot guarantee the absolute security of your personal information. In the event of a security breach, we will notify affected users promptly and take immediate steps to mitigate the impact and prevent future breaches.
              </blockquote>

            <blockquote>
              For any questions related to the security of your personal information, please contact us at easyspeakllc@gmail.com.
            </blockquote>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">6. Changes to This Privacy Policy</h3>
            <p className="info-list">
              We may update this Privacy Policy and will notify you of any changes by posting the new policy on our website.
            </p>
            </div>

          <div className="privacy-policy-agreement-container">
            <h3 className="privacy-policy-agreement-container-title">7. Contact Us</h3>
          <p className="info-list">
            If you have any questions or concerns about this Privacy Policy, please contact us at: <a className="easy-policy-email" href="mailto:easyspeakllc@gmail.com">easyspeakllc@gmail.com</a>
          </p>
          </div>
        </div>
      </div>
        <Footer />
    </div>
  ) 
}