/*
  This component is the second section of the home page. 
  It contains a video that explains the product.

  While its mainly static, it contains state logic to handle the resizing of the video, and text formatting.
*/

import React, { useState, useEffect } from "react";
import "./section_2.css";
export default function SectionTwo() {
  // State to hold the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update the window width on resize
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array ensures this runs once on mount and unmount

  // Your existing rendering logic
  
  if (windowWidth <= 315) {
    return (  
      <div className="section-2-container">
        <div className="section-2-content-container">
            <div className="section-2-content-header">
              <p>Speaking made easy</p>
            </div>
            <div className="section-2-content-text">
              <h3>Watch why we created</h3>
              <h3>EasySpeak</h3>
              <h3> and how we're here to help </h3>
              <h3>improve your pronunciation</h3>
            </div>
            <div className="section-2-content-video">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JKrl3a2A5JI?si=0EtzX1JB5hYMPKre" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
        </div>
      </div>
    )
  }
  if (windowWidth <= 610) {
  return (  
    <div className="section-2-container">
      <div className="section-2-content-container">
          <div className="section-2-content-header">
            <p>Speaking made easy</p>
          </div>
          <div className="section-2-content-text">
            <h3>Watch why we created EasySpeak</h3>
            <h3> and how we're here to help </h3>
            <h3>improve your pronunciation</h3>
          </div>
          <div className="section-2-content-video">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JKrl3a2A5JI?si=0EtzX1JB5hYMPKre" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
      </div>
    </div>
  )
  } 
  else {
    return (  
      <div className="section-2-container">
        <div className="section-2-content-container">
            <div className="section-2-content-header">
              <p>Speaking made easy</p>
            </div>
            <div className="section-2-content-text">
              <h3>Watch why we created EasySpeak and how we're here to help </h3>
              <h3>improve your pronunciation</h3>
            </div>
            <div className="section-2-content-video">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JKrl3a2A5JI?si=0EtzX1JB5hYMPKre" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
        </div>
      </div>
    )
  }
}