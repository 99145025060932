/* 
  This holds the logic for the navbar, including the mobile version linkage of the navbar
  it requires the param of: pageName
*/
import React  from 'react';
import mainLogo from "../../../../images/mainLogo.svg"
import MobileNav from './mobile/index.jsx';
import "./navbar.css";

/* 
  This package handles the smooth scrolling of the page 
  but it does not help with navigation across pages
*/ 
import { Link } from 'react-scroll'; 


export default function TopMenu({page}) {


  // This function handles the navigation of the pages across the website
  const navigator = (e, location) => {
    e.preventDefault();
    if(location ==='about'){
      window.location.href = "/about";
    }
    if(location ==='how'){
      window.location.href = "/";
      localStorage.setItem('scrollTo', 'how-it-works');
    }
    if(location ==='contact'){
      window.location.href = "/";
      localStorage.setItem('scrollTo', 'connect-with-us');
    }if(location ==='index'){
      window.location.href = "/";
    }
    if(location ==='waitlist'){
      window.location.href = "/";
      localStorage.setItem('scrollTo', 'waitlist');
    }
    else{
      return;
    }
  };

/* 
  This is the navbar for the Home page,
  it has a different structure from the 'other' page
  and it has a different mobile navbar structure
  it uses the react-scroll package to handle the smooth scrolling

  The mobile version of the navbar is handled by the MobileNav component
  which is imported from the mobile folder

  The render logic is based on media queries that are handled by the css file ./navbar.css
*/
  if(page === 'home'){
    return (
      <div className="navbar-container">
        
        
        <div className="navbar-brand ">
          <img className='navbar-brand-logo' src={mainLogo} alt="logo" onClick={(e)=>{navigator(e, 'index')}} />
          <p className="navbar-brand-text " onClick={(e)=>{navigator(e, 'index')}}>EasySpeak</p>
        </div>
        
        
        <div className="navbar-cta">

          <div className="desktop-nav navbar-cta-buttons-container">
            <button className="navbar-cta-buttons how-button" >
              <Link to="how-it-works">How it works</Link>
            </button>
            <button className="navbar-cta-buttons contact-button" >
              <Link to='connect-with-us'>Contact</Link>
            </button>
            <button className="navbar-cta-buttons about-button" 
              onClick={(e)=>{navigator(e, 'about')}}>
                About
            </button>
            <button className='waitlist-button' onClick={ (e)=>{navigator(e, 'waitlist')} } > 
              Join waitlist 
            </button>
          </div>
          
          {/* this is the mobile nav bar call but its render logic lives in ./navbar.css */}
          <div className='mobile-nav burgerMenu'>
            <MobileNav source={'home'} />
          </div>
        
        </div>


      </div>
    );
  }

/* 
  This is the navbar for the every 'other' page,
  it has a different structure from the Home page
  and it has a different mobile navbar structure
  it uses the navigator function to handle the smooth scrolling

  it works by changing the window location href to the desired page
  and it uses the local storage to store the scroll position
  the logic is in ../../App.js


  The mobile version of the navbar is handled by the MobileNav component
  which is imported from the mobile folder

  The render logic is based on media queries that are handled by the css file ./navbar.css
*/
  if(page === 'other'){
    return (
      <div className="navbar-container">
        <div className="navbar-brand">
          <img className='navbar-brand-logo'src={mainLogo} alt="logo" onClick={(e)=>{navigator(e, 'index')}} />
          <p className="navbar-brand-text " onClick={(e)=>{navigator(e, 'index')}}>EasySpeak</p>
        </div>
        <div className="navbar-cta">
          <div className="desktop-nav navbar-cta-buttons-container">
            <button className="navbar-cta-buttons how-button" onClick={(e)=>{navigator(e, 'how')}}>
              How it works
            </button>
            <button className="navbar-cta-buttons contact-button" >
              <Link to='connect-with-us'>Contact</Link>
            </button>
            <button className="navbar-cta-buttons about-button" onClick={(e)=>{navigator(e, 'about')}}>
              About
            </button>
            <button className='waitlist-button' onClick={ (e)=>{navigator(e, 'waitlist')} } > 
              Join waitlist 
            </button>
          </div>

          {/* this is the mobile nav bar call but its render logic lives in ./navbar.css */}
          <div className='mobile-nav burgerMenu'>
            <MobileNav source={'about'} />
          </div>
        </div>
      </div>
    );
  }
}
