/* 
  This page holds the logic that connects all the components as well as route them
*/
import React from "react";
import { Routes, Route } from 'react-router-dom' 
import { Helmet } from 'react-helmet'; // This allows to change the title of the page dynamically


// Components imports
import Home from "./components/_homePage/home";
import About from "./components/_aboutPage/about";
import PrivatePolicy from "./components/_termsPage/privacy-policy/policy";
import TermsOfService from "./components/_termsPage/terms-of-service/terms";
// import Demo from "./components/_demoPage/demo";



function App() {

  // This is the website home page
  const HomePage = () => {

    /*
      This the logic that allows the nav to work outside the '/' page
      if inside local storage it has a 'scrollTo' item it will slide the page
      to the selected section with -100px below the top of screen
      this allows the nav to not overlay the selected section/div
    */
    let scrollTo = localStorage.getItem('scrollTo');
    if (scrollTo) {
      setTimeout(() => {
        const element = document.getElementById(scrollTo);
        if (element) {
          const elementRect = element.getBoundingClientRect();
          const absoluteElementTop = elementRect.top + window.pageYOffset;
          const offsetTop = absoluteElementTop + (-100);  // Adjusting by -100 pixels
          window.scrollTo(0, offsetTop);
        }
        localStorage.removeItem('scrollTo'); // once it has scrolled it removes it to avoid future unwanted scrolls
      }, 100);
    }
    return (
      <div>
        <Helmet>
          <title>EasySpeak | Welcome</title>
        </Helmet>
        <Home />
      </div>
    )
  }

  // This is the website about page
  const AboutPage = () => {
    return (
      <div>
        <Helmet>
          <title>EasySpeak | About</title>
        </Helmet>
        <About />
      </div>
    )
  }

  // This is the website terms page
  const PrivatePolicyPage = () => {
    return (
      <div>
        <Helmet>
          <title>EasySpeak | Privacy Policy</title>
        </Helmet>
        <PrivatePolicy />
      </div>
    )
  }

  const TermsOfServicePage = () => {
    return(
    <div>
        <Helmet>
          <title>EasySpeak | Terms of Service</title>
        </Helmet>
        <TermsOfService />
      </div>
    )
  }

  // This is the website demo page (for testing)
  // const DemoPage = () => {
  //   return (
  //     <div>
  //       <Helmet>
  //         <title>EasySpeak | Demo</title>
  //       </Helmet>
  //       <Demo />
  //     </div>
  //   )
  // }
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy-policy" element={<PrivatePolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        {/* <Route path="/demo" element={<DemoPage />} /> */}
        <Route path="*" element={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
