/* 
  This is the function that sends any writes to firebase 
  it requires to param: the data in object form and the designated table
  if no table exist it will auto create it
*/
import { db } from "./connection"
import { collection, addDoc} from "firebase/firestore"

export const FirestoreSendOff = async (data, table) => {
  console.log('here is the data', data)
  try {
  console.log('here is the data', 2, data)
    const docRef = await addDoc(collection(db, table), {
      ...data
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

