/* 
  This the logic for the mobile version of the navbar
  its the same as the desktop version but with a different render logic
  mainly styling and closing the navbar when a link is clicked/clicked outside of the navbar
*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import "./index.css";
import { FaBars, FaTimes } from 'react-icons/fa';

export default function MobileNav({source}) {

  // This is the state that handles the opening and closing of the mobile navbar
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest('.mobile-navbar-container')) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen]);

  const mobileNav = document.getElementsByClassName('nav-menu')
  // this adds event listeners to the mobile navbar links to close the navbar when a link is clicked
  for(let i = 0; i < mobileNav.length; i++){
    mobileNav[i].addEventListener('click', function(e) {
      setIsOpen(false);
    })
  }

  // This function handles the navigation of the pages across the website
  const navigator = (e, location) => {
    e.preventDefault();
    setIsOpen(false);
    console.log('here');
    if(location ==='about'){
      window.location.href = "/about";
    }
    if(location ==='how'){
      window.location.href = "/";
      localStorage.setItem('scrollTo', 'how-it-works');
    }
    if(location ==='contact'){
      window.location.href = "/";
      localStorage.setItem('scrollTo', 'connect-with-us');
    }if(location ==='index'){
      window.location.href = "/";
    }
    if(location ==='waitlist'){
      window.location.href = "/";
      localStorage.setItem('scrollTo', 'waitlist');
    }
    else{
      return;
    }
  };

  if(source === 'home'){
  return (
    <div className='mobile-navbar-container'>
      <div className="burger" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <FaTimes size={32} color='White' /> : <FaBars size={32} />}
      </div>

      <div className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <button className="mobile-navbar-cta-buttons how-button" onClick={(e)=>{navigator(e, '')}} >
          <Link to="how-it-works">How it works</Link>
        </button>
        <button className="mobile-navbar-cta-buttons contact-button" >
          <Link to='connect-with-us'>Contact</Link>
        </button>
        <button className="mobile-navbar-cta-buttons about-button" onClick={(e)=>{navigator(e, 'about')}}>
          About
        </button>
        {/* <div className='waitlist-button-mobile-contianer'> */}
            <button className='waitlist-button-mobile' onClick={ (e)=>{navigator(e, 'waitlist')} } > 
              Join waitlist 
            </button>
          {/* </div> */}

      </div>
    </div>
  );
  }
  
  if(source === 'about'){
    return (
      <div className='mobile-navbar-container'>
        <div className="burger" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <FaTimes size={32} color='White' /> : <FaBars size={32} />}
        </div>
  
        <div className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <button className="mobile-navbar-cta-buttons how-button" onClick={(e)=>{navigator(e, 'how')}}>How it works</button>
                <button className="mobile-navbar-cta-buttons contact-button" ><Link to='connect-with-us'>Contact</Link></button>
                <button className="mobile-navbar-cta-buttons about-button" 
                  onClick={(e)=>{navigator(e, 'about')}}>
                    About
                </button>
                <button className='waitlist-button-mobile' onClick={ (e)=>{navigator(e, 'waitlist')} } > 
              Join waitlist 
            </button>
        </div>
      </div>
    );
  }
}