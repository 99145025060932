/*
  This is the build for the reviews and team section of the homepage
  It is mainly static and does not require any state management
  It is imported in the home.jsx file
*/
import React from "react";
import "./section_5.css";
import Team from "./a_team/team";
import Reviews from "./b_reviews/reviews";
export default function SectionFive() {
  return (
    <div className="section-5-container">
      <Team />
      <Reviews />
    </div>
  );
}