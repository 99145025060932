/*
  This is the file that builds the fourth section of the home page.
  This section is divided into three parts:
  1. Voice
  2. Mouth
  3. Excercises

  The three parts are built in their respective folders and then imported here.

  This the component that gets imported in App.js and then rendered in the home page.
*/
import React from "react";
import "./section_4.css";
import Voice from "./a_voice/voice";
import Mouth from "./b_mouth/mouth";
import Excercises from "./c_excercises/excercises";
export default function SectionFour() {
  return (
    <div className="section-four-container">
      <Voice />
      <Mouth />
      <Excercises />
    </div>
  );
}