/* 
  This holds the waitlist form for the landing page
  It can also be used for other pages, all it needs to be properly imported where needed
*/

import React, { useState } from "react";
import "./waitlist.css";
import { FirestoreSendOff } from "../../database/firebase/sendOffs"
import { v4 as uuidv4 } from 'uuid';
import { User, EnvelopeSimple } from "@phosphor-icons/react"
import useRecaptchaV3 from "../reCaptchaV3";

export default function Waitlist() {
  // This is the site key for the reCAPTCHA component
  const executeRecaptcha = useRecaptchaV3(process.env.REACT_APP_CAPTCHA_APP_SITE_KEY);






  // Textarea placeholder state clear on focus logic
  const [placeholder, setPlaceholder] = useState("Tell us what you think...");

  const handleFocus = () => {
      setPlaceholder("");
  };

  const handleBlur = (e) => {
      if(e.target.value === "") {
          setPlaceholder("Tell us what you think...");
      }
  };






  // Form state logic for input fields and validation
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("_empty_");
  const [terms, setTerms] = useState(false);
  
  // Validation logic for form submission
  const validateForm = async (e) => {
    e.preventDefault();
    let nameInput = document.getElementsByClassName('name-input')[0]
    let emailInput = document.getElementsByClassName('email-input')[0]
    let commentInput = document.getElementsByClassName('comment-input')[0]
    let nameError = document.getElementById('name-error')
    let emailError = document.getElementById('email-error')
    let agreementError = document.getElementById('terms-error')


    /*
      the eslint-disable-line comment is used to ignore 
      the warning that comes with using the RegExp constructor
      since the warning is not necessary in this case 
      since a eslint style guide is not being followed
      since the regex is being used to validate an email address 
      it has extra checks that are necessary for a valid email
    */

    // eslint-disable-next-line
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$');

    // it needs to have all required fields filled out and email needs to be valid
    if(name !== "" && email !== "" && terms === true){
      if(validEmail.test(email)){
        const reCaptchaToken = await executeRecaptcha('waitlist')
        const formValues = {
          u_id: `${uuidv4()}`,
          full_name: name,
          email: email,
          comment: comment,
          dateCreated: new Date(),
          token: reCaptchaToken
        }
        FirestoreSendOff(formValues, 'waitlist')

        setName("");
        setEmail("");
        setComment("");
        setTerms(false);
        nameInput.value = "";
        emailInput.value = "";
        commentInput.value = "";
        localStorage.setItem('agreement', 'false');
        localStorage.setItem('firstName', '');
        localStorage.setItem('lastName', '');
        localStorage.setItem('email', '');
        localStorage.setItem('feedback', '');
        let agreementBox = document.getElementsByClassName('waitlist-terms-input')[0]
        agreementBox.checked = false;
        nameError.setAttribute("style", "display: none;")
        emailError.setAttribute("style", "display: none;")
        agreementError.setAttribute("style", "display: none;")
        
      }else{
        emailError.setAttribute("style", "color: red")
      }
    }else{
      if(name === ""){
        // fNameLabel.setAttribute("style", "color: #FF0000;")
        nameError.setAttribute("style", "color: red")
      }
      if(email === "" || !validEmail.test(email)){
        // emailLabel.setAttribute("style", "color: #FF0000;")
        emailError.setAttribute("style", "color: red")
      }
      if(terms === false){
        agreementError.setAttribute("style", "color: red")
        let link_1 = document.getElementsByClassName('terms-link')[0]
        let link_2 = document.getElementsByClassName('terms-link')[1]
        link_1.classList.add("tos-pp-text")
        link_2.classList.add("tos-pp-text")
      }
    }
  }


  return (
    <div id="waitlist" className="waitlist-container">
      <form onSubmit={validateForm} className="waitlist-form" noValidate>
          <div className="input-container">
            <input className="input-with-icon name-input" type="text" placeholder="Full name" onChange={(e)=>setName(e.target.value)} />
            <span className="input-icon"><User size={24} color="#757575" /></span>
          </div>
          <div id="name-error" className="form-error" style={{color: 'white', display:'none'}} >
            <p>Please enter your name</p>
          </div>
          <div className="input-container">
            <input className="input-with-icon email-input" type="email" placeholder="Email address" onChange={(e)=>setEmail(e.target.value)}  />
            <span className="input-icon"><EnvelopeSimple size={24} color="#757575" /></span>
          </div>
          <div id="email-error" className="form-error" style={{color: 'white', display:'none'}}>
            <p>Please enter a valid email</p>
          </div>
          <div className="input-container">
          <textarea 
              className="input-textbox comment-input" 
              name="feedback"
              placeholder={placeholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e)=>setComment(e.target.value)}
              ></textarea>          </div>
          <div className="input-container TOS-container-form">
            <input className="form-checkbox terms waitlist-terms-input"  type="checkbox" name="terms" id="terms" onClick={(e)=>{setTerms(prevTerms => !prevTerms)}} />
            <p className="terms-label">I agree to the <span className="tos-pp-text" onClick={(e) => { e.stopPropagation(); window.location.href='/terms-of-service' }}>Terms of Service</span> and the <span className="tos-pp-text" onClick={(e) => { e.stopPropagation(); window.location.href='/privacy-policy' }}>Privacy Policy</span></p>
          </div>
          <div id="terms-error" className="form-error" style={{color: 'white', display:'none'}}>
            <p>Agreement to the <span className="terms-link" onClick={(e) => { e.stopPropagation(); window.location.href='/terms-of-service' }}>Terms of Service</span> and <span className="terms-link" onClick={(e) => { e.stopPropagation(); window.location.href='/privacy-policy'}}>Privacy Policy</span> is required</p>
          </div>
          <div className="input-button-container">
            <button type="submit" className="input-button">Sign up</button>
          </div>
      </form>
    </div>
  );
}