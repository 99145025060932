/* 
  Theses are global functions that are use in the project,
  Instead of writing them multiple times I can import it and use it anywhere
*/

// logic for connection buttons
export const linkConnectionSendOff = (e, name) =>{
  e.preventDefault();
  if(name === 'LinkedIn'){
    window.open('https://www.linkedin.com/company/easyspeakllc/about/', '_blank');
  }else if(name === 'Email'){
    window.location.href = "mailto:easyspeakllc@gmail.com";
  }
}


// proper date format for firebase
export const dateConversion = async (date) => {

  const options = {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit',
    timeZoneName: 'short',
    timeZone: 'America/Los_Angeles' 
  };
  
  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(date);
}