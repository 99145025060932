/*
  This the first component that gets rendered in the home page.
  It renders the waitlist component from: ../../global/components/waitlist/waitlist

  While its mainly static, it contains text, an image and form component.
  This is the component that gets imported in App.js and then rendered in the home page.
*/

import React from "react";
import Waitlist from "../../global/components/waitlist/waitlist";
import mascot_desktop from "../../../images/mascot_animation.gif";
import './section_1.css'

export default function SectionOne() {
  return (
    <div className='section-1-container'>
        <div className='section-1-text-container'>
          <div className="section-1-mascot-container">
            <img className='section-1-mascotGIF'src={mascot_desktop} alt="mascot-animation_gif" />
            <div className="gif-block">-</div>
          </div>
          <p className='section-1-text-header'>Join the waitlist</p>
          <p className='section-1-text-body'>We're stirring up quite a buzz with our mobile app currently in development.</p>
          <p className='section-1-text-body'>Join our community and get excited to improve your pronunciation </p>
          <p className='section-1-text-body'>Sign up to be notified when our mobile app is available for download!</p>
        </div>
        <Waitlist />
    </div>
  );
} 