/* 
  this component is the footer of the website,
  its overall static and does not require any props
*/
import React from "react";
import './footer.css';
import envelope from "../../../../images/icons/email-white-clear.svg"
import linkedin_clear from "../../../../images/icons/linkedin-white-clear.svg"
import { linkConnectionSendOff } from "../../functions/functions.js";
export default function Footer() {
  return (
    <div className="footer-container">

      <div className="footer-container-content">
        <div className="footer-container-content-left">
          <p className="footer-container-left-title">EasySpeak</p>
          <p className="footer-container-left-subtitle">How can we help?</p>
          <p className="footer-container-left-text">At EasySpeak, we are</p>
          <p className="footer-container-left-text">committed to helping you</p>
          <p className="footer-container-left-text">improve your pronunciation</p>
        </div>
        <div className="footer-container-content-right">
          <div className="footer-right-icon-container">
              <img className="footer-left-icon" onClick={(e)=>{linkConnectionSendOff(e,"", 'Email' )}} src={envelope} alt="Email" />
              <img className="footer-right-icon" onClick={(e)=>{linkConnectionSendOff(e,"", 'LinkedIn' )}} src={linkedin_clear} alt="LinkedIn" />
          </div>
        </div>
      </div>

      <div className="footer-text-container-bottom-solo">
              <div className="footer-container-left-bottom">
                <p>© 2024 EasySpeak, LLC. All Rights Reserved.</p>
              </div>
              <div className="footer-container-right-bottom">
                <p>Speaking made easy</p>
              </div>
      </div>
    </div>
  );
}