/*
  This is the build of the about page.
  This is the component that gets imported in App.js and then rendered in the about page.

  This component is mainly static

  This gets imported in App.js and then rendered in the about page
*/

import React from "react";
import TopMenu from '../global/components/nav/navbar';
import SectionOne from "./section_1/section_1";
import SectionTwo from "./section_2/section_2";
import ConnectionButtons from "../global/components/connectButtons/connectionButtons";
import Footer from "../global/components/footer/footer";


export default function About() {
  return (
    <div>
      <TopMenu page ={'other'}/>
      <SectionOne />
      <SectionTwo />
      <ConnectionButtons />
      <Footer />
    </div>
  );
}