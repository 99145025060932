/* 
  This file holds the build for the homepage('/')
  
  This is the function that gets imported in App.js 
*/
import React from 'react';
import TopMenu from '../global/components/nav/navbar';
import SectionOne from './section_1/section_1';
import SectionTwo from './section_2/section_2';
import SectionThree from './section_3/section_3';
import SectionFour from './section_4/section_4';
import SectionFive from './section_5/section_5';
import ConnectionButtons from '../global/components/connectButtons/connectionButtons';
import Footer from '../global/components/footer/footer';
import "./home.css";


export default function Home() {

  return (
    <div className='main-body-container'>
      <TopMenu page={'home'} />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <ConnectionButtons />
      <Footer />
    </div>
  );
}